<template>
  <div class="overflow-x-auto pb-6">
    <vs-alert class="mb-4" color="success">
      <span class="flex items-center gap-2">
        <feather-icon icon="InfoIcon" svgClasses="h-4 w-4"/>
        <span>{{$t('All prices are subject to all applicable taxes')}}</span>
      </span>
    </vs-alert>
    <div style="min-width: 650px;">
      <div class="grid grid-cols-4 whitespace-no-wrap font-semibold items-center h-20" style="background-color: #F0F7FF;">
        <div class="border border-b-0 border-t-0 border-l-0 border-solid border-black px-2 flex gap-2 items-center justify-center">
          <span class="material-icons-outlined text-2xl">location_on</span>
          <p>{{$t('Governorate')}}</p>
        </div>
        <div class="border border-b-0 border-t-0 border-l-0 border-solid border-black px-2 flex gap-2 items-center justify-center">
          <span class="material-icons-outlined text-2xl">location_on</span>
          <p>{{$t('City')}}</p>
        </div>
        <div class="border border-b-0 border-t-0 border-l-0 border-solid border-black px-2 flex gap-2 items-center justify-center">
          <span class="material-icons-outlined text-2xl">location_on</span>
          <p>{{$t('Zone')}}</p>
        </div>
        <div class="px-2 flex gap-2 items-center justify-center">
          <span class="material-icons-outlined text-2xl">payments</span>
          <p>{{$t('Surcharge Fees')}}</p>
        </div>
      </div>
      <div v-if="zoneSurcharges.length === 0" class="p-4 text-center">
        <p>{{'No Data Available'}}</p>
      </div>
      <div v-for="item in zoneSurcharges" :key="item.index">
        <div class="grid grid-cols-4 justify-items-center mt-7 font-semibold text-center">
          <p class="border border-b-0 border-t-0 border-l-0 border-solid border-black w-full px-2"> 
            {{ item.destination_zone.city.governorate.name }}
          </p>
          <p class="border border-b-0 border-t-0 border-l-0 border-solid border-black w-full px-2"> 
            {{ item.destination_zone.city.name }}
          </p>
          <p class="border border-b-0 border-t-0 border-l-0 border-solid border-black w-full px-2"> 
            {{ item.destination_zone.name }}
          </p>
          <p class="text-center w-full"> 
            {{ $t('EGP') }} {{ item.value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {sendRequest} from '@/http/axios/requestHelper.js'
import common from '@/assets/utils/common.js'

export default {
  data () {
    return {
      merchant: {},
      zoneSurcharges: []
    }
  },
  methods: {
    loadPricingLookups () {
      sendRequest(false, false, this, `api/v1/merchants/${this.merchant.id}/pricing-lookups/`, 'get', null, true, 
        (response) => {
          this.zoneSurcharges = response.data.pricing_lookup_delivery.destination_zone_surcharge_lookup
        }
      )
    }
  },
  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    merchantData.then(results => {
      this.merchant = results.merchantData
      this.loadPricingLookups()
    })
  }
}
</script>