<template>
  <div id="page-user-view" class="px-4 pb-6">
    <p class="mb-base flex-shrink-0 text-2xl font-medium">{{$t('Account Details')}}</p>
    <vs-alert class="mb-base" active="true" color="warning" icon-pack="feather" icon="icon-alert-triangle" v-if="merchantStatus === 'on_hold'">
      {{$t('Your account is')}} <b>{{$t('On hold')}}</b> {{$t('due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.')}}
    </vs-alert>

    <div v-else>
      <div v-if="taxProfileAdded === false">
        <p class="font-medium text-lg mb-3 leading-tight text-black">{{ $t('Select Your Account Type') + '*' }}</p>
        <div class="flex gap-6">
          <label v-for="(type, index) in accountTypes" :key="index" :class="account === type.value ? 'text-primary border-primary' : 'border-grey'" class="bg-white flex gap-1 sm:flex-no-wrap flex-wrap items-start cursor-pointer border border-solid rounded px-4 py-2 w-68 sm:h-20">
            <div class="flex leading-loose gap-2">
              <input @change="account = type.value" :value="type.value" type="radio" name="type">
              <span>{{ type.name }}</span>
            </div>
            <p class="material-symbols-outlined text-4xl text-right w-full self-center select-none">{{ type.icon }}</p>
          </label>
        </div>
      </div>
      <div v-else-if="taxProfileAdded === true">
        <p class="font-medium text-lg mb-3 leading-tight text-black">{{ $t('Account Type') }}</p>
        <div class="flex items-start text-primary border border-solid border-primary rounded px-4 py-2 sm:w-68 w-64 h-20">
          <span>{{ taxData.company ? 'Company' : 'Personal' }}</span>
          <p class="material-symbols-outlined text-4xl text-right w-full self-center select-none">{{ taxData.company ? 'apartment': 'person' }}</p>
        </div>
      </div>
      
      <div v-if="account" class="grid sm:grid-cols-2 grid-cols-1 gap-6 mt-6 xl:w-2/3">
        <template v-if="account === 'company'">
          <div class="col-span-1">
            <p class="font-medium text-lg mb-3 leading-tight text-black">{{ $t('Legal Name') + `${taxProfileAdded ? '' : '*'}` }}</p>
            <p v-if="taxProfileAdded" style="color: #737373;" class="text-lg">{{ taxData.company.name }}</p>
            <div v-else>
              <vs-input class="w-full" v-model="tax.name" v-validate="{required: true, regex: /^[a-zA-Z0-9 -]*$/ }" key="legalName" name="legal name"/>
              <span class="text-danger text-sm" v-show="errors.has('legal name')">{{ tax.name ? $t('The legal name') + $t(' field only accepts english letters and english numbers') : $t('The legal name') + $t(' field is required') }}</span>
            </div>
          </div>
          <div class="col-span-1">
            <p class="font-medium text-lg mb-3 leading-tight text-black">{{ $t('Tax Registration Number') + `${taxProfileAdded ? '' : '*'}` }}</p>
            <p v-if="taxProfileAdded" style="color: #737373;" class="text-lg">{{ taxData.company.tax_registration_number }}</p>
            <div v-else>
              <vs-input class="w-full" v-model="tax.tax_registration_number" v-validate="'required|numeric|min:9|max:9'" key="taxRegistrationNumber" name="tax registration number"/>
              <span class="text-danger text-sm" v-show="errors.has('tax registration number')">{{ tax.tax_registration_number && tax.tax_registration_number.length === 9 ? $t('The tax registration number') + $t(' field only accepts english numbers') : tax.tax_registration_number && tax.tax_registration_number.length !== 9 ? $t('The tax registration number') + $t(' field must be 9 numbers') : $t('The tax registration number') + $t(' field is required') }}</span>
            </div>
          </div>
        </template>

        <template v-if="account === 'person'">
          <div class="col-span-1">
            <p class="font-medium text-lg mb-3 leading-tight text-black">{{ $t('Full Name') + `${taxProfileAdded ? '' : '*'}` }}</p>
            <p v-if="taxProfileAdded" style="color: #737373;" class="text-lg">{{ taxData.person.name }}</p>
            <div v-else>
              <vs-input class="w-full" v-model="tax.name" v-validate="{required: true, regex: /^[a-zA-Z -]*$/ }" key="fullName" name="full name"/>
              <span class="text-danger text-sm" v-show="errors.has('full name')">{{ tax.name ? $t('The full name') + $t(' field only accepts english letters') : $t('The full name') + $t(' field is required') }}</span>
            </div>
          </div>
          <div class="col-span-1">
            <p class="font-medium text-lg mb-3 leading-tight text-black">{{ $t('National ID') + `${taxProfileAdded ? '' : '*'}` }}</p>
            <p v-if="taxProfileAdded" style="color: #737373;" class="text-lg">{{ taxData.person.national_id }}</p>
            <div v-else>
              <vs-input class="w-full" v-model="tax.national_id" v-validate="'required|numeric|min:14|max:14'" key="nationalId" name="national id"/>
              <span class="text-danger text-sm" v-show="errors.has('national id')">{{ tax.national_id && tax.national_id.length === 14 ? $t('The national id') + $t(' field only accepts english numbers') : tax.national_id && tax.national_id.length !== 14 ? $t('The national id') + $t(' field must be 14 numbers') : $t('The national id') + $t(' field is required') }}</span>
            </div>
          </div>
        </template>

        <div class="col-span-1">
          <p class="font-medium text-lg mb-3 leading-tight text-black">{{ $t('Governorate') + `${taxProfileAdded ? '' : '*'}` }}</p>
          <p v-if="taxProfileAdded" style="color: #737373;" class="text-lg">{{ taxData.company ? taxData.company.governorate : taxData.person.governorate }}</p>
          <div v-else>
            <vs-input class="w-full" v-model="tax.governorate" v-validate="{required: true, regex: /^[a-zA-Z -]*$/}" name="governorate"/>
            <span class="text-danger text-sm" v-show="errors.has('governorate')">{{ tax.governorate ? $t('The governorate') + $t(' field only accepts english letters') : $t('The governorate') + $t(' field is required') }}</span>
          </div>
        </div>
        <div class="col-span-1">
          <p class="font-medium text-lg mb-3 leading-tight text-black">{{ $t('City') + `${taxProfileAdded ? '' : '*'}` }}</p>
          <p v-if="taxProfileAdded" style="color: #737373;" class="text-lg">{{ taxData.company ? taxData.company.city : taxData.person.city }}</p>
          <div v-else>
            <vs-input class="w-full" v-model="tax.city" v-validate="{required: true, regex: /^[a-zA-Z0-9 -]*$/}" name="city"/>
            <span class="text-danger text-sm" v-show="errors.has('city')">{{ tax.city ? $t('The city') + $t(' field only accepts english letters and english numbers') : $t('The city') + $t(' field is required') }}</span>
          </div>
        </div>
        <div class="col-span-1">
          <p class="font-medium text-lg mb-3 leading-tight text-black">{{ $t('Street') + `${taxProfileAdded ? '' : '*'}` }}</p>
          <p v-if="taxProfileAdded" style="color: #737373;" class="text-lg">{{ taxData.company ? taxData.company.street : taxData.person.street }}</p>
          <div v-else>
            <vs-input class="w-full" v-model="tax.street" v-validate="{required: true, regex: /^[a-zA-Z0-9() -'.?!,:;_]*$/}" name="street"/>
            <span class="text-danger text-sm" v-show="errors.has('street')">{{ tax.street ? $t('The street') + $t(' field only accepts english letters and english numbers') : $t('The street') + $t(' field is required') }}</span>
          </div>
        </div>
        <div class="col-span-1">
          <p class="font-medium text-lg mb-3 leading-tight text-black">{{ $t('Building Number') + `${taxProfileAdded ? '' : '*'}` }}</p>
          <p v-if="taxProfileAdded" style="color: #737373;" class="text-lg">{{ taxData.company ? taxData.company.building_number : taxData.person.building_number }}</p>
          <div v-else>
            <vs-input class="w-full" v-model="tax.building_number" v-validate="{required: true, regex: /^[a-zA-Z0-9() -'.?!,:;_]*$/}" name="building number"/>
          <span class="text-danger text-sm" v-show="errors.has('building number')">{{ tax.building_number ? $t('The building number') + $t(' field only accepts english letters and english numbers') : $t('The building number') + $t(' field is required') }}</span>
          </div>
        </div>
        <div class="sm:col-span-2 col-span-1">
          <p class="font-medium text-lg mb-3 leading-tight text-black">{{ $t('Attach Document' + ' ' + `${account === 'company' ? '(' + $t('Tax Registration ID') + ')' : '(' + $t('National ID') + ')'}`) + `${taxProfileAdded ? '' : '*'}` }}</p>
          <div v-if="taxProfileAdded" class="flex flex-wrap gap-6">
            <div class="w-36 h-24 rounded overflow-hidden" v-for="(image, index) in taxData.documents" :key="index">
              <img class="w-full" :src="image.document_url" :alt="image.document_name">
            </div>
          </div>
          <div v-else>
            <input type="file" @input="onSelectFile" class="hidden" ref="fileInput" accept="image/jpg, image/jpeg, application/pdf">
            <div
              @click="$refs.fileInput.click()"
              @drop="handleDrop"
              @dragover="handleDragover"
              @dragenter="handleDragover"
              class="p-8 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl rounded-md">
              <div v-if="images.length === 0" class="py-8">
                <feather-icon icon="UploadCloudIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block" />
                <span>{{$t('Drop Image or')}} </span>
                <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">{{$t('Browse')}}</span>
              </div>
              <div v-else class="flex flex-wrap gap-6 justify-center">
                <img v-for="image in images" :key="image.index" class="w-20 h-20" :src="image"/>
              </div>
            </div>
            <span class="text-danger text-sm" v-if="clicks > 0 && images.length === 0">{{$t('This field is required')}}</span>
          </div>
        </div>
        <div v-if="!taxProfileAdded">
          <vs-button class="text-lg py-4 px-16" @click="confirmSubmission()">{{$t('Submit')}}</vs-button>
        </div>
      </div>
    </div>

    <add-tax-profile :tax="tax" :account="account" :images="images" :sentImages="sentImages" :taxModal="taxModal" @taxModal="taxModal = $event" @loadTaxProfile="loadTaxProfile"/>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import AddTaxProfile from './components/AddTaxProfile.vue'

export default {
  props: ['merchantStatus'],
  data () {
    return {
      taxModal: false,
      taxProfileAdded: null,
      images: [],
      sentImages: [],
      clicks: 0,
      taxData: {},
      tax: {},
      account: '',
      accountTypes: [
        {
          name: 'Company',
          value: 'company',
          icon: 'apartment'
        },
        {
          name: 'Personal',
          value: 'person',
          icon: 'person'
        }
      ]
    }
  },
  methods: {
    onSelectFile (event) {
      const [file] = this.$refs.fileInput.files
      if (file) {
        this.images.push(URL.createObjectURL(file))
        this.sentImages.push(event.target.files[0])
      }
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isImage(rawFile)) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only supports upload .png, .jpg suffix files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }
    },
    isImage (file) {
      return /\.(jpeg|jpg|pdf)$/.test(file.name)
    },
    confirmSubmission () {
      this.clicks++
      this.$validator.validateAll().then(result => {
        if (result && this.images.length > 0) {
          this.taxModal = true
        }
      })
    },
    loadTaxProfile () {
      sendRequest(false, false, this, 'api/v1/accounting/tax-profiles/', 'get', null, true, 
        (response) => {
          this.taxData = response.data.results[0]
          this.taxProfileAdded = response.data.count > 0
          this.account = this.taxData.company ? 'company' : 'person'
          this.showDocuments()
        }
      )
    },
    showDocuments () {
      sendRequest(false, false, this, `api/v1/accounting/tax-profiles/${this.taxData.id}/file-upload/`, 'get', null, true, 
        (response) => {
          this.taxData.documents = response.data.documents
        }
      )
    }
  },
  components: {
    AddTaxProfile
  },
  created () {
    this.loadTaxProfile()
  }
}
</script>