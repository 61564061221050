<template>
  <shipblu-card :cardLoading="transactionHistoryLoader" :class="transactionHistoryLoader ? 'px-4' : ''" class="py-4 xl:absolute w-full h-full vs-con-loading__container" id="transactions-history-loading">
    <div class="xl:flex flex-col h-full">
      <p class="text-xl text-primary font-medium flex-shrink-0 px-4">{{$t('Transactions History')}}</p>
      <div class="flex flex-wrap gap-2 items-center text-center mt-3 mb-6 flex-shrink-0 px-4">
        <div v-for="(item, index) in transactionFilters" :key="index" @click="changeTransactionsFilter(item)" :class="item === $route.query.filter ? 'active' : ''" class="transaction-filter font-medium py-1 px-3 cursor-pointer">
          <p class="capitalize">{{$t(item)}}</p>
        </div>
      </div>
      <div v-if="transactions.length > 0" @scroll="handleScroll" class="xl:h-full h-96 overflow-y-auto px-4">
        <div v-for="(transaction, index) in transactions" :key="index" class="sm:flex gap-2 py-2 items-center justify-between border border-l-0 border-r-0 border-t-0 border-dashed border-grey">
          <div class="flex items-center gap-6">
            <p class="material-symbols-outlined text-primary font-light">{{transaction.type === 'deposit' ? ($i18n.locale === 'en' ? 'arrow_forward_ios' : 'arrow_back_ios') : ($i18n.locale === 'en' ? 'arrow_back_ios' : 'arrow_forward_ios')}}</p>
            <div>
              <p class="text-black font-medium capitalize">{{$t('transaction '+transaction.type)}}</p>
              <p class="text-grey-dark">{{(new Date(transaction.created).toISOString().split('T')[0]).split('-').join('/') + ' - ' + transaction.description}}</p>
            </div>
          </div>
          <div class="ml-auto flex justify-between w-40 text-grey-dark">
            <p>{{$t('EGP')}}</p>
            <p>{{(transaction.type === 'deposit' ? '+ ' : '- ') + Number(transaction.amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
          </div>
        </div>
      </div>
      <div v-else class="p-4 text-center">
        <p>{{'No Data Available'}}</p>
      </div>
    </div>
  </shipblu-card>
</template>

<script>
import { sendRequest } from '../../../http/axios/requestHelper'
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'

export default {
  data () {
    return {
      disable: false,
      offset: 0,
      currentPage: 1,
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      transactionHistoryLoader: false,
      transactionFilters: ['all', 'deposit', 'payout', 'refunds', 'supplies'],
      transactions: []
    }
  },
  watch: {
    '$route.query.filter' () {
      this.disable = false
      this.offset = 0
      this.currentPage = 1
      this.loadTransactionsHistory(true, true)
    }
  },
  methods: {
    changeTransactionsFilter (filter) {
      this.$router.push({
        query: {
          ...this.$route.query,
          filter
        }
      }).catch(() => {})
    },
    handleScroll (el) {
      if ((el.target.offsetHeight + el.target.scrollTop) >= (window.screen.width > 1283 ? el.target.scrollHeight : el.target.scrollHeight - 1)  && !this.disable) {
        this.loadTransactionsHistory(true)
      }
    },
    loadTransactionsHistory (scrollLoader, filter) {
      scrollLoader ? this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#transactions-history-loading',
        scale: 0.45
      }) : this.transactionHistoryLoader = true
      const query = this.$route.query.filter === 'all' ? `?offset=${this.offset}&limit=${this.maximumItems}` : `?type=${this.$route.query.filter}&offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/accounting/merchant-financial-events/${query}`, 'get', null, true, 
        (response) => {
          this.transactions = scrollLoader && !filter ? [...this.transactions, ...response.data.results] : response.data.results
          if (scrollLoader) {
            this.disable = response.data.next === null
            this.currentPage++
            this.offset = (this.currentPage - 1) * this.maximumItems
          }
          scrollLoader ? this.$vs.loading.close('#transactions-history-loading > .con-vs-loading') : this.transactionHistoryLoader = false
        }, () => {
          scrollLoader ? this.$vs.loading.close('#transactions-history-loading > .con-vs-loading') : this.transactionHistoryLoader = false
        }
      )
    }
  },
  components: {
    ShipbluCard
  },
  created () {
    this.loadTransactionsHistory(false)
  }
}
</script>

<style lang="scss">
.transaction-filter {
  border: 1px solid #7F7F7F;
  border-radius: 20px;
  min-width: 70px;
  &.active {
    border: 1px solid #1c5bfe;
    color: #1c5bfe;
  }
}
</style>