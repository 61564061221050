<template>
  <div>
    <vs-alert class="mt-5" color="success">
      <span class="flex items-center">
        <feather-icon
          icon="InfoIcon"
          svgClasses="h-4 w-4"
          class="mr-2"
        />
        <span>{{$t('All prices include all applicable taxes.')}}</span>
      </span>
    </vs-alert>
    <div class="flex items-center gap-3 mt-4">
      <p class="text-lg font-semibold">{{$t('Origin Governorate:')}}</p>
      <div v-if="originGovernorateFlag" @click="originGovernorateFlag = false" class="fixed inset-0 bg-transparent"></div>
      <div class="relative w-76">
        <div @click="originGovernorateFlag = !originGovernorateFlag" class="flex gap-4 cursor-pointer p-2 rounded-lg whitespace-no-wrap overflow-hidden">
          <p class="truncate w-full capitalize font-semibold text-blue-100">{{pickupPoint ? pickupPoint.address.zone.city.governorate.name : ''}}</p>
          <div class="text-right flex items-center">
            <feather-icon class="w-4 h-4" :icon="originGovernorateFlag ? 'ChevronUpIcon' : 'ChevronDownIcon'"></feather-icon>
          </div>
        </div>
        <div v-if="originGovernorateFlag" class="absolute w-full left-0 bg-white shadow-md p-2 rounded-lg z-50 " style="top: 40px;">
          <div class="hover:bg-blue-100 hover:text-white" @click="pickupPoint.address.zone.city.governorate.name = item.name , loadPricingLookups() , originGovernorateFlag = false" v-for="item in governorates.filter(item => item.id === 1 || item.id === 2 || item.id === 16)" :key="item.index">
              <span class="capitalize">{{ $t(item.name) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-6 overflow-x-auto">
      <div style="min-width: 900px;">
        <div class="grid grid-cols-5 font-semibold items-center h-20" style="background-color:#F0F7FF;">
          <div class="w-full px-2 text-center border border-b-0 border-t-0 border-l-0 border-solid border-black">
            <p>{{$t('Destination Governorate')}}</p>
          </div>
          <div class="border border-b-0 border-t-0 border-l-0 border-solid border-black px-2 flex gap-2 items-center justify-center">
            <span class="material-icons-outlined text-2xl" >inventory_2</span>
            <p>{{$t('Delivery')}}</p>
          </div>
          <div class="border border-b-0 border-t-0 border-l-0 border-solid border-black px-2 flex gap-2 items-center justify-center">
            <span class="material-icons-outlined text-2xl" style="transform:scaleX(-1);" >local_shipping</span>
            <p>{{$t('Returns')}}</p>
          </div>
          <div class="border border-b-0 border-t-0 border-l-0 border-solid border-black px-2 flex gap-2 items-center justify-center">
            <span class="material-icons-outlined text-2xl" >repeat</span>
            <p>{{$t('Exchange')}}</p>
          </div>
          <div class="flex gap-2 items-center justify-center">
            <span class="material-icons-outlined text-2xl" >payments</span>
            <p>{{$t('Cash Collection')}}</p>
          </div>
        </div>
        <div v-if="pricing.length === 0" class="p-4 text-center">
          <p>{{'No Data Available'}}</p>
        </div>
        <div v-for="item in pricing" :key="item.index" class="mt-2">
          <div class="bg-white shadow rounded flex items-center justify-between px-10 py-4 text-blue-100">
            <p class="font-bold text-lg">{{item.destination_governorate}}</p> 
            <p class="font-semibold text-sm">{{$t('Service Level') }}:  {{item.sizesDelivery[item.destination_governorate][0].service_level.name}}</p>
          </div>
          <div class="grid grid-cols-5 justify-items-center mt-7" >
            <div class="font-semibold text-black w-full border border-b-0 border-t-0 border-l-0 border-solid border-black">
              <p class="px-4">{{'Small Flyer'}}</p> <p class="px-4 mb-7 text-sm">{{'20x25cm'}}</p> 
              <p class="px-4">{{'Medium Flyer'}}</p> <p class="px-4 mb-7 text-sm">{{'40x40cm'}}</p> 
              <p class="px-4">{{'Large Flyer'}}</p> <p class="px-4 mb-7 text-sm">{{'45x50cm'}}</p> 
              <p class="px-4">{{'Extra Large Flyer'}}</p> <p class="px-4 mb-7 text-sm">{{'50x60cm'}}</p> 
            </div>
            <div v-if="item.sizesDelivery[item.destination_governorate].length > 0" class="grid grid-rows-4 text-center w-full border border-b-0 border-t-0 border-l-0 border-solid border-black"> 
              <p class="mb-7 font-semibold">{{'EGP'}} {{(item.sizesDelivery[item.destination_governorate].filter(item => item.package_size.id === 1)[0].value * 1.14).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
              <p class="mb-7 font-semibold">{{'EGP'}} {{(item.sizesDelivery[item.destination_governorate].filter(item => item.package_size.id === 2)[0].value * 1.14).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
              <p class="mb-7 font-semibold">{{'EGP'}} {{(item.sizesDelivery[item.destination_governorate].filter(item => item.package_size.id === 3)[0].value * 1.14).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
              <p class="mb-7 font-semibold">{{'EGP'}} {{(item.sizesDelivery[item.destination_governorate].filter(item => item.package_size.id === 4)[0].value * 1.14).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
            </div>
            <div v-if="item.sizesReturn[item.destination_governorate].length > 0" class="grid grid-rows-4 text-center w-full border border-b-0 border-t-0 border-l-0 border-solid border-black"> 
              <p class="mb-7 font-semibold">{{'EGP'}} {{(item.sizesReturn[item.destination_governorate].filter(item => item.package_size.id === 1)[0].value * 1.14).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
              <p class="mb-7 font-semibold">{{'EGP'}} {{(item.sizesReturn[item.destination_governorate].filter(item => item.package_size.id === 2)[0].value * 1.14).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
              <p class="mb-7 font-semibold">{{'EGP'}} {{(item.sizesReturn[item.destination_governorate].filter(item => item.package_size.id === 3)[0].value * 1.14).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
              <p class="mb-7 font-semibold">{{'EGP'}} {{(item.sizesReturn[item.destination_governorate].filter(item => item.package_size.id === 4)[0].value * 1.14).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
            </div>
            <div v-if="item.sizesExchange[item.destination_governorate].length > 0" class="grid grid-rows-4 text-center w-full border border-b-0 border-t-0 border-l-0 border-solid border-black"> 
              <p class="mb-7 font-semibold">{{'EGP'}} {{(item.sizesExchange[item.destination_governorate].filter(item => item.package_size.id === 1)[0].value * 1.14).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
              <p class="mb-7 font-semibold">{{'EGP'}} {{(item.sizesExchange[item.destination_governorate].filter(item => item.package_size.id === 2)[0].value * 1.14).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
              <p class="mb-7 font-semibold">{{'EGP'}} {{(item.sizesExchange[item.destination_governorate].filter(item => item.package_size.id === 3)[0].value * 1.14).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
              <p class="mb-7 font-semibold">{{'EGP'}} {{(item.sizesExchange[item.destination_governorate].filter(item => item.package_size.id === 4)[0].value * 1.14).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
            </div>
            <div v-if="item.sizesCollection[item.destination_governorate].length > 0" class="grid grid-rows-4"> 
              <p class="mb-7 font-semibold">{{'EGP'}} {{(item.sizesCollection[item.destination_governorate][0].value * 1.14).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import common from '../../../assets/utils/common.js'

export default {
  data () {
    return {
      deliveryPricing: {},
      originGovernorateFlag: false,
      serviceLevels: [],
      pickupPoints: [],
      pickupPoint: '',
      merchant: {},
      pricing: [],
      pricingLookup: {},
      pricingLookupLoading: false,
      governorates:[
        {
          id: 1,
          name: 'Cairo - القاهرة'
        },
        {
          id: 2,
          name: 'Alexandria - الاسكندرية'
        },
        {
          id: 16,
          name: 'Giza - الجيزة'
        }
      ]
    }
  },
  methods: {
    loadPricingLookups () {
      this.$vs.loading()
      this.pricing = []
      sendRequest(true, false, this, `api/v1/merchants/${this.merchant.id}/pricing-lookups/`, 'get', null, true, 
        (response) => {
          this.pricingLookup = response.data
          const deliveryPricing = Object.groupBy(response.data.pricing_lookup_delivery.base_pricing_lookup, ({ origin_governorate }) => origin_governorate.name)
          const returnPricing = Object.groupBy(response.data.pricing_lookup_return.base_pricing_lookup, ({ origin_governorate }) => origin_governorate.name)
          const exchangePricing = Object.groupBy(response.data.pricing_lookup_exchange.base_pricing_lookup, ({ origin_governorate }) => origin_governorate.name)
          const cashCollectionPricing = Object.groupBy(response.data.pricing_lookup_cash_collection.base_pricing_lookup, ({ origin_governorate }) => origin_governorate.name)
          const delivery = Object.groupBy(deliveryPricing[this.pickupPoint.address.zone.city.governorate.name], ({ destination_governorate }) => destination_governorate.name)
          const returns = Object.groupBy(returnPricing[this.pickupPoint.address.zone.city.governorate.name], ({ destination_governorate }) => destination_governorate.name)
          const exchange = Object.groupBy(exchangePricing[this.pickupPoint.address.zone.city.governorate.name], ({ destination_governorate }) => destination_governorate.name)
          const cashCollection = Object.groupBy(cashCollectionPricing[this.pickupPoint.address.zone.city.governorate.name], ({ destination_governorate }) => destination_governorate.name)
          Object.keys(delivery).forEach((key) => {
            this.pricing.push({
              origin_governorate: this.pickupPoint.address.zone.city.governorate,
              destination_governorate: key,
              sizesDelivery: delivery,
              sizesReturn: returns,
              sizesExchange: exchange,
              sizesCollection: cashCollection
            })
          })
        }
      )
    },
    loadPickupPoints () {
      sendRequest(false, false, this, `api/v1/merchants/${this.merchantID}/pickup-points/`, 'get', null, true,
        (response) => {
          this.pickupPoints = response.data.results
          this.pickupPoints.forEach(element => {
            if (element.is_default) {
              this.pickupPoint = element
            }
          })
          this.loadPricingLookups()
        }
      )
    }
  },
  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    merchantData.then(results => {
      this.merchant = results.merchantData
      this.merchantID = this.merchant.id
      this.loadPickupPoints()
    })
  }
}
</script>