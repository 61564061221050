<template>
  <shipblu-prompt
    class="shipment-modal tax-confirmation-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="taxModal"
    title=""
    :buttons-hidden="true"
    autocomplete="nofill">
    <div class="px-2 mb-2">
      <p class="text-grey-dark text-lg font-medium leading-tight mb-8 border-0 border-b border-solid border-grey-light pb-6">
        {{ $t('Please double-check the account type details before submitting, as they can’t be modified afterwards.') }}
      </p>
      <div class="grid sm:grid-cols-2 grid-cols-1 mt-6 gap-6">
        <div>
          <p class="font-medium text-lg mb-2 leading-tight italic">{{ account === 'company' ? $t('Legal Name') : $t('Full Name') }}</p>
          <p style="color: #737373;">{{ tax.name }}</p>
        </div>
        <div>
          <p class="font-medium text-lg mb-2 leading-tight italic">{{ account === 'company' ? $t('Tax Registration Number') : $t('National ID') }}</p>
          <p style="color: #737373;">{{ account === 'company' ? tax.tax_registration_number : tax.national_id }}</p>
        </div>
        <div>
          <p class="font-medium text-lg mb-2 leading-tight italic">{{ $t('Governorate') }}</p>
          <p style="color: #737373;">{{ tax.governorate }}</p>
        </div>
        <div>
          <p class="font-medium text-lg mb-2 leading-tight italic">{{ $t('City') }}</p>
          <p style="color: #737373;">{{ tax.city }}</p>
        </div>
        <div>
          <p class="font-medium text-lg mb-2 leading-tight italic">{{ $t('Street') }}</p>
          <p style="color: #737373;">{{ tax.street }}</p>
        </div>
        <div>
          <p class="font-medium text-lg mb-2 leading-tight italic">{{ $t('Building Number') }}</p>
          <p style="color: #737373;">{{ tax.building_number }}</p>
        </div>
        <div class="sm:col-span-2 col-span-1">
          <p class="font-medium text-lg mb-3 leading-tight italic">{{ $t('Attach Document' + ' ' + `${account === 'company' ? '(' + $t('Tax Registration ID') + ')' : '(' + $t('National ID') + ')'}`) }}</p>
          <div class="flex flex-wrap gap-4">
            <div class="w-24 h-16 rounded overflow-hidden" v-for="(image, index) in images" :key="index">
              <img :src="image" class="w-full" alt="image">
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-12">
        <vs-button color="#8599B3" @click="closeModal" class="py-3 font-semibold">{{ $t('Cancel') }}</vs-button>
        <vs-button @click="addTax" class="py-3 font-semibold">{{ $t('Confirm') }}</vs-button>
      </div>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import {sendRequest} from '../../../http/axios/requestHelper.js'

export default {
  props: ['taxModal', 'tax', 'account', 'images', 'sentImages'],
  methods: {
    changeImage (taxID) {
      const formData = new FormData()
      this.sentImages.forEach(item => {
        formData.append('documents', item)
      })
      sendRequest(false, false, this, `api/v1/accounting/tax-profiles/${taxID}/file-upload/`, 'post', formData, true,
        () => {}
      )
    },
    closeModal () {
      this.$emit('taxModal', false)
      this.$emit('loadTaxProfile')
    },
    addTax () {
      this.clicks++
      this.$validator.validateAll().then(result => {
        if (result && this.images.length > 0) {
          this.$vs.loading()
          const taxObj = {
            type: this.account,
            name: this.tax.name,
            governorate: this.tax.governorate !== '' ? this.tax.governorate : null,
            city: this.tax.city !== '' ? this.tax.city : null,
            street: this.tax.street ? this.tax.street : null,
            building_number: this.tax.building_number ? this.tax.building_number : null,
            tax_registration_number: this.tax.tax_registration_number ? this.tax.tax_registration_number : null,
            national_id: this.tax.national_id ? this.tax.national_id : null
          }
          sendRequest(true, false, this, 'api/v1/accounting/tax-profiles/', 'post', taxObj, true,
            (response) => {
              this.changeImage(response.data.id)
              setTimeout(() => {
                this.$vs.loading.close()
                this.closeModal()
                location.reload()
              }, 2000)
            }
          )
        }
      })
    }
  },
  components: {
    ShipbluPrompt
  }
}
</script>

<style lang="scss">
.tax-confirmation-modal {
  .vs-dialog {
    width: 450px;
    & > div:first-child {
      display: none;
    }
    .prompt-font {
      padding: 30px !important;
    }
  }
}
</style>