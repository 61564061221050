<template>
  <div id="page-user-view">
    <export-button class="lg:float-left float-right clearfix mt-6 whitespace-nowrap mb-0"></export-button>
    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      :data="invoices"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :tableLoader="tableLoader"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Invoice Date')}}</shipblu-th>
        <shipblu-th>{{$t('Invoice Number')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Subtotal')}}</shipblu-th>
        <shipblu-th>{{$t('VAT')}}</shipblu-th>
        <shipblu-th>{{$t('Total')}}</shipblu-th>
        <shipblu-th>{{$t('Download Invoice')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].created">
            {{ new Date(data[indextr].created).toLocaleDateString('fr-CA')}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].number">
            {{ data[indextr].number }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].bill_to_customer_name">
            {{ data[indextr].bill_to_customer_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].subtotal">
            {{ Number(data[indextr].subtotal).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].vat">
            {{ Number(data[indextr].vat).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].total">
            {{ Number(data[indextr].total).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].pdf_link">
            <a @click="downloadInvoice(data[indextr])" target="_blank">{{$t('Download')}}</a>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import common from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ExportButton from '../headOfFleet/components/ExportButton.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      invoices: [],
      selected: [],
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      file_URl: '',
      searchInProgress: false,
      searchedValue: ' ',
      searchVal: '',
      tableLoader: false
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          ...this.$route.query,
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadInvoices()
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadInvoices()
    },
    downloadInvoice (data) {
      sendRequest(false, false, this, `api/v1/billing/invoices/${data.id}/pdf/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadInvoicesSearch)
    },
    loadInvoicesSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadInvoices()
    },
    loadInvoices () {
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/billing/invoices/${query}`, 'get', null, true, 
        (response) => {
          this.invoices = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    }
  },
  components: {
    ExportButton,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadInvoices()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>