<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="payoutModal"
    :title="$t('Add Payout')"
    :buttons-hidden="true" autocomplete="nofill">
    <div class="vx-col w-1/2 mb-10">
      <v-select v-validate="'required'" class="mt-10 w-full" name="type" v-model="payout.payoutType" :placeholder="$t('Type')" label="name" :options="types"/>
      <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
    </div>
    <div class="vx-row" v-if="payout.payoutType && payout.payoutType.value === 'bank'">
      <div class="vx-col w-full">
        <v-select v-validate="'required'" class="mt-8 w-full" name="bank name" v-model="payout.bank" :placeholder="$t('Bank Name')" label="name" :options="banks"/>
        <span class="text-danger text-sm" v-show="errors.has('bank name')">{{ errors.first('bank name') }}</span>
      </div>
      <div class="vx-col w-full">
        <vs-input ref="account number" @cut.prevent @copy.prevent @paste.prevent v-validate="'required'" name="account name" :label-placeholder="$t('Account Name') + '*'" v-model="payout.creditor_name" class="mt-8 w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('account name')">{{ errors.first('account name') }}</span>
      </div>
      <div class="vx-col w-1/2">
        <vs-input ref="account number" @cut.prevent @copy.prevent @paste.prevent v-validate="'required|alpha_num|min:6'" name="account number" :label-placeholder="$t('Account Number') + '*'" v-model="payout.account_number" class="mt-8 w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('account number')">{{ errors.first('account number') }}</span>
      </div>
      <div class="vx-col w-1/2">
        <vs-input @cut.prevent @copy.prevent @paste.prevent v-validate="{ required: true, confirmed:'account number'}" name="confirm account number" :label-placeholder="$t('Confirm Account Number') + '*'" v-model="payout.confirm_account_number" class="mt-8 w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('confirm account number')">{{ errors.first('confirm account number') }}</span>
      </div>
    </div>
    <div v-else-if="payout.payoutType && payout.payoutType.value === 'wallet'" class="vx-row">
      <div class="vx-col w-1/2">
        <vs-input ref="mobile wallet" @cut.prevent @copy.prevent @paste.prevent v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" name="mobile wallet" :label-placeholder="$t('Mobile Wallet') + '*'" v-model="payout.number" class="mt-8 w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('mobile wallet')">{{ errors.first('mobile wallet') }}</span>
      </div>
      <div class="vx-col w-1/2">
        <vs-input @cut.prevent @copy.prevent @paste.prevent v-validate="{ required: true, confirmed:'mobile wallet', regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" name="confirm mobile wallet" :label-placeholder="$t('Confirm Account Number') + '*'" v-model="payout.confirm_account_number" class="mt-8 w-full"/>
        <span class="text-danger text-sm" v-show="errors.has('confirm mobile wallet')">{{ errors.first('confirm mobile wallet') }}</span>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-4">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button @click="pinOpen" class="active-btn btn">{{ $t('Add') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import i18nData from '../../../i18n/i18nData.js'

export default {
  props: ['payoutModal', 'payout', 'pinModal'],
  data () {
    return {
      types: [
        {
          name: 'Bank Account',
          value: 'bank'
        },
        {
          name: 'Mobile Wallet',
          value: 'wallet'
        }
      ],
      banks: [
        {
          'name': 'البنك العربى الافريقى الدولى (AAIB)'
        },
        {
          'name': 'بنك المؤسسة العربية المصرفية – مصر (ABC)'
        },
        {
          'name': 'بنك الأهلي الكويتي - مصر (ABK)'
        },
        {
          'name': 'بنك البركة - مصر (ABRK)'
        },
        {
          'name': 'بنك أبو ظبى الاسلامى - مصر (ADIB)'
        },
        {
          'name': 'بنك الإستثمار العربي (AIB)'
        },
        {
          'name': 'البنك العربى (ARAB)'
        },
        {
          'name': 'المصرف العربي الدولي (ARIB)'
        },
        {
          'name': 'البنك الأهلي المتحد (AUB)'
        },
        {
          'name': 'بنك عَوده (AUDI)'
        },
        {
          'name': 'التجاري وفابنك إيجيبت ش.م.م (BBE)'
        },
        {
          'name': 'بنك القاهرة (BDC)'
        },
        {
          'name': 'بنك بلوم - مصر (BLOM)'
        },
        {
          'name': 'بنك الأسكندرية (BOA)'
        },
        {
          'name': 'بنك كريدى أجريكول (CAE)'
        },
        {
          'name': 'البنك المركزي المصري (CBE)'
        },
        {
          'name': 'البنك التجارى الدولى (CIB)'
        },
        {
          'name': 'سيتي بنك ان ايه - مصر (CITI)'
        },
        {
          'name': 'البنك العقارى المصرى العربى (EALB)'
        },
        {
          'name': 'البنك المصرى لتنمية الصادرات (EDBE)'
        },
        {
          'name': 'البنك المصرى الخليجى (EGB)'
        },
        {
          'name': 'بنك الإمارات دبي الوطنى (ENBD)'
        },
        {
          'name': 'بنك ابو ظبي الأول (FAB)'
        },
        {
          'name': 'بنك فيصل الإسلامى المصرى (FAIB)'
        },
        {
          'name': 'بنك التعمير والإسكان (HDB)'
        },
        {
          'name': 'بنك اتش اس بى سى مصر (HSBC)'
        },
        {
          'name': 'بنك التنمية الصناعية (IDB)'
        },
        {
          'name': 'بنك المشرق (MASH)'
        },
        {
          'name': 'ميد بنك (MIDB)'
        },
        {
          'name': 'بنك مصر (MISR)'
        },
        {
          'name': 'البنك الأهلي المصري (NBE)'
        },
        {
          'name': 'البنك الأهلي اليوناني (NBG)'
        },
        {
          'name': 'بنك الكويت الوطنى - مصر (NBK)'
        },
        {
          'name': 'بنك ناصر الاجتماعي (NSB)'
        },
        {
          'name': 'البنك الزراعي المصري (PDAC)'
        },
        {
          'name': 'البريد المصري (POST)'
        },
        {
          'name': 'بنك قطر الوطنى الأهلى (QNB)'
        },
        {
          'name': 'بنك الشركة العربية المصرفية الدولية (SAIB)'
        },
        {
          'name': 'بنك قناة السويس (SCB)'
        },
        {
          'name': 'المصرف المتحد(UB)'
        },
        {
          'name': 'بنك ابو ظبى التجارى – مصر ش.م .م (ADCB)'
        }
      ]
    }
  },
  components: {
    vSelect,
    ShipbluPrompt
  },
  watch: {
    'payout.payoutType' () {
      this.$validator.validate()
    }
  },
  methods: {
    closeModal () {
      this.$emit('payoutModal', false)
      this.$emit('pinModal', false)
      this.$emit('loadPayments')
    },
    pinOpen () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.sendOTP()
          this.$emit('pinModal', true)
        }
      })
    },
    sendOTP () {
      sendRequest(false, false, this, 'api/v1/accounting/payout-methods/otp/', 'post', {}, true,
        (response) => {
          this.$vs.notify({
            color: 'success',
            title: i18nData[this.$i18n.locale]['Success'],
            text: response.data.message,
            position: 'top-center'
          })
        }
      )
    }
  }
}
</script>