<template>
  <div>
    <shipblu-card class="p-4">
      <div class="flex sm:flex-row flex-col justify-between gap-2">
        <div class="grid justify-between" style="max-width: 500px;">
          <div class="flex flex-wrap gap-x-4 justify-between font-medium text-xl pb-3 text-primary border border-dashed border-t-0 border-r-0 border-l-0 border-grey" style="height: fit-content;">
            <p>{{$t('Your Wallet Balance')}}</p>
            <div class="flex ml-auto gap-6">
              <p>{{$t('EGP')}}</p>
              <p>{{merchant.quickbooks_wallet_balance ? merchant.quickbooks_wallet_balance.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.0'}}</p>
            </div>
          </div>
          <p class="my-2">{{$t('Deposit any amount into your account, fueling supplies requests and ensuring smooth customer refunds.')}}</p>
          <div class="flex items-center">
            <div @click="merchant.is_self_signup && merchant.name === 'Entrepreneur Merchant' ? topUpModal = false :  topUpModal = true" type="filled" :class="merchant.is_self_signup && merchant.name === 'Entrepreneur Merchant' ? 'disabled-btn' : 'active-btn' " class="cursor-pointer flex items-center leading-tight gap-1 bg-primary rounded-lg text-white p-3 font-semibold">
            <p class="text-xl leading-none">+</p>
              <p>{{$t('Deposit')}}</p>
            </div>
          </div>
        </div>
        <div class="sm:block hidden">
          <img src="@/assets/images/pages/top-up.png" alt="" height="200">
        </div>
      </div>
    </shipblu-card>
    <top-up-method v-if="topUpModal" @topUpModal="topUpModal = $event" />
  </div>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import TopUpMethod from './TopUpMethod.vue'

export default {
  props: ['merchant'],
  data () {
    return {
      topUpModal: false
    }
  },
  components: {
    ShipbluCard,
    TopUpMethod
  }
}
</script>