<template>
  <div v-if="accessFinancials">
    <h2 class="mb-5">{{$t('My Account')}}</h2>
    <vs-tabs v-model="activeTab" :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" :key="isSmallerScreen">
      <vs-tab icon-pack="feather" icon="icon-credit-card" :label="!isSmallerScreen ? $t('Overview') : ''">
        <div class="md:ml-4 md:mt-0 mt-4 ml-0">
          <wallet />
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-briefcase" :label="!isSmallerScreen ? $t('Account Details') : ''">
        <div class="md:ml-4 md:mt-0 mt-4 ml-0">
          <tax-profile :merchantStatus="merchantStatus"/>
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-dollar-sign" :label="!isSmallerScreen ? $t('Pricing') : ''">
        <div class="md:ml-4 md:mt-0 mt-4 ml-0">
          <user-merchant-pricing />
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-dollar-sign" :label="!isSmallerScreen ? $t('Zone Surcharges') : ''">
        <div class="md:ml-4 md:mt-0 mt-4 ml-0">
          <zone-surcharges />
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-credit-card" :label="!isSmallerScreen ? $t('Payout Methods') : ''">
        <div class="md:ml-4 md:mt-0 mt-4 ml-0">
          <payout-methods :merchantStatus="merchantStatus" />
        </div>
      </vs-tab>
    </vs-tabs>

    <!-- Successful Transaction Popup -->
    <div v-if="topUpTransaction.transaction_status === 'SUCCESSFUL'" class="top-up-method-container p-4 flex items-center justify-center">
      <div class="rounded-lg card w-full overflow-y-auto py-4 sm:px-6 px-4 vs-con-loading__container" id="top-up-loading">
        <div class="flex items-center justify-between mb-4">
          <p class="font-medium text-primary text-xl">{{$t('Debit/Credit Card Top-up')}}</p>
          <p @click="topUpTransaction = {}" class="material-symbols-outlined cursor-pointer text-2xl">close</p>
        </div>
        <div class="my-8">
          <p class="text-lg font-medium">
            <span>{{$t('Your request to deposit an amount of EGP')}}</span>
            <span class="text-primary">{{topUpTransaction.base_amount ? (' ' + Number(topUpTransaction.base_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' ') : ' '}}</span>
            <span>{{$t('has been received and your account will be updated within 2 Business Days.')}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PayoutMethods from './PayoutMethods.vue'
import TaxProfile from './TaxProfile.vue'
import Invoices from './Invoices.vue'
import UserMerchantPricing from '../pages/user-settings/UserMerchantPricing.vue'
import ZoneSurcharges from './ZoneSurcharges.vue'
import common from '../../assets/utils/common'
import Wallet from './Wallet.vue'

export default {
  data () {
    return {
      activeTab: 0,
      activeTabNameDic: {'overview': 0, 'account-details': 1, 'pricing': 2, 'zone-surcharges': 3, 'payout-methods': 4, 'tax-profile': 5},
      activeTabDic: {0: 'overview', 1: 'account-details', 2: 'pricing', 3: 'zone-surcharges', 4: 'payout-methods', 5: 'tax-profile'},
      merchantStatus: '',
      topUpTransaction: {},
      accessFinancials:true
    }
  },
  watch: {
    '$route.query.tab' () {
      if (this.activeTab === 0) {
        this.$router.push({
          query: {
            tab: this.activeTabDic[this.activeTab],
            walletTab: this.$route.query.walletTab ? this.$route.query.walletTab : 'statements',
            page: this.$route.query.page ? Number(this.$route.query.page) : 1,
            filter: this.$route.query.filter ? this.$route.query.filter : 'all'
          }
        }).catch(() => {})
      } else {
        this.$router.push({
          query: {
            tab: this.activeTabDic[this.activeTab],
            page: 1
          }
        }).catch(() => {})
      }
    },
    'activeTab' () {
      if (this.activeTab === 0) {
        this.$router.push({
          query: {
            tab: this.activeTabDic[this.activeTab],
            walletTab: this.$route.query.walletTab ? this.$route.query.walletTab : 'statements',
            page: this.$route.query.page ? Number(this.$route.query.page) : 1,
            filter: this.$route.query.filter ? this.$route.query.filter : 'all'
          }
        }).catch(() => {})
      } else {
        this.$router.push({
          query: {
            tab: this.activeTabDic[this.activeTab],
            page: this.$route.query.page ? Number(this.$route.query.page) : 1
          }
        }).catch(() => {})
      }
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  components: {
    PayoutMethods,
    TaxProfile,
    UserMerchantPricing,
    ZoneSurcharges,
    Invoices,
    Wallet
  },
  mounted () {
    this.topUpTransaction = this.$route.query
    const merchantData = common.checkMerchantInLocalStorage(this)
    let userMerchant = {}
    let user = {}
    merchantData.then(results => {
      userMerchant = results.userMerchantData
      user = results.merchantData
      this.accessFinancials = userMerchant.access_financials
      if (this.accessFinancials === false) {
        this.$router.push({name: 'merchant-dashboard'}).catch(() => {})
      }
      this.merchantStatus = user.status
    })
  },
  created () {
    this.activeTab = this.$route.query.tab ? this.activeTabNameDic[this.$route.query.tab] : '0'  
  }
}
</script>
